<template>
  <div class="projectMenu"
    v-show="!$store.state.fullScreen">
    <div class="toggle"
      @click="toggleMenu">
      <span :class="['icon', 'iconfont', (isCollapse? 'iconzzj_leftmenu_contract': 'iconzzj_leftmenu_contract_in')]"
        style="font-size: 18px;"></span>
    </div>
    <el-menu :default-active="activePath"
      class="el-menu-vertical-demo"
      background-color="#3f3f3f"
      text-color="#ffffff"
      active-text-color="#ffffff"
      :collapse="isCollapse">
      <template v-for="route in menuData">
        <menu-item v-if="route.meta.name"
          :key="route.path"
          :item="route"
          :base-path="route.path" />
      </template>
    </el-menu>
  </div>
</template>

<script lang=js>
  import router from '@/router';
  import MenuItem from './MenuItem.vue';
  import { menuList } from './menu';

  export default {
    name: 'LeftMenu',
    components: {
      MenuItem,
    },
    data() {
      return {
        routes: router,
        projectMenu: false,
      };
    },
    computed: {
      isCollapse() {
        return this.$store.state.isCollapse;
      },
      activePath() {
        const route = this.$route;
        const { meta, path } = route;
        if (meta.activeMenu) {
          return meta.activeMenu;
        }
        return path;
      },
      menuData() {
        const menuFilter = (menus) => {
          const ret = [];
          menus
            .filter((menu) => menu.code === undefined || this.$store.state.permissions.includes(menu.code))
            .forEach((menu) => {
              if (menu.children) {
                menu.children = menuFilter(menu.children);
                if (menu.children.length > 0) {
                  ret.push(menu);
                }
              } else {
                ret.push(menu);
              }
            });
          return ret;
        };
        const menus = menuList[this.$route.matched[1].path.split('/')[1]];
        return menuFilter(JSON.parse(JSON.stringify(menus)));
      },
    },
    methods: {
      toggleMenu() {
        const isCollapse = Number(!this.isCollapse);
        this.$store.commit('isCollapse', isCollapse);
      },
    },
  };
</script>

<style lang="less">
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
    .is-active {
      background-color: #02d6dc !important;
    }
  }

  .el-menu-vertical-demo.el-menu--collapse {
    .is-active {
      background-color: #02d6dc !important;
      .el-submenu__title {
        background-color: #02d6dc !important;
        color: #ffffff;
      }
    }
  }
  .projectMenu {
    height: 100%;
    position: relative;
    background-color: #3f3f3f;
    z-index: 999;
    .el-menu--collapse {
      width: 50px;
      text-align: center;
    }

    .el-menu {
      border: none;
    }

    .toggle {
      height: 30px;
      background: #545c64;
      text-align: center;
      color: #fff;
      line-height: 30px;
      font-size: 20px;
      cursor: pointer;
    }

    .el-submenu.is-opened .el-menu-item{
      background: #232323!important;
    }
    .el-submenu.is-opened .el-menu-item.is-active{
      background: #02d6dc!important;
    }

    .el-menu-item-group__title {
      padding: 0 0 0 20px !important;
    }

    .el-menu-item-group .el-menu-item {
      padding-left: 49px !important;
    }

    .el-menu-item,
    .el-submenu__title {
      height: 50px;
      line-height: 50px;
    }

    .el-submenu .el-menu-item {
      height: 40px;
      line-height: 40px;
    }

    .el-menu--collapse {
      width: 50px;
      text-align: center;

      .el-submenu__title {
        padding: 0 !important;
      }

      .el-menu-item {
        padding: 0 !important;

        .el-tooltip {
          padding: 0 !important;
        }
      }
    }
  }
</style>
