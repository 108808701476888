export const menuList = {
  home: [
    {
      path: '/home/system',
      meta: {
        name: '系统首页',
        icon: 'iconindex',
      },
    },
    {
      path: '/2',
      meta: {
        name: '账户信息',
        icon: 'iconusermanagement',
      },
      children: [
        {
          path: '/home/account',
          meta: {
            name: '账户设置',
            icon: '',
          },
        },
        {
          path: '/home/message',
          meta: {
            name: '消息中心',
            icon: '',
          },
        },
      ],
    },
    {
      path: '/home/systemInfo',
      meta: {
        name: '系统信息',
        icon: 'iconibiconsetting',
      },
    },
  ],
  product: [
    {
      path: '/product1',
      meta: {
        name: '产品管理',
        icon: 'iconchanpin',
      },
      children: [
        {
          path: '/product/list',
          code: 'ProductMenu',
          meta: {
            name: '产品列表',
            icon: '',
          },
        },
        {
          path: '/product/audit',
          code: 'ProductAuditMenu',
          meta: {
            name: '产品审核',
            icon: '',
          },
        },
        {
          path: '/product/recycleBin',
          code: 'ProductRecycleBinMenu',
          meta: {
            name: '产品回收站',
            icon: '',
          },
        },
      ],
    },
    {
      path: '/product2',
      meta: {
        name: '产品配置',
        icon: 'iconchanpinpeizhi',
      },
      children: [
        {
          path: '/product/classification',
          code: 'ProductTypeMenu',
          meta: {
            name: '分类管理',
            icon: '',
          },
        },
        {
          path: '/product/type',
          code: 'ProductCategoryMenu',
          meta: {
            name: '类型管理',
            icon: '',
          },
        },
        {
          path: '/product/brand',
          code: 'BrandMenu',
          meta: {
            name: '品牌管理',
            icon: '',
          },
        },
      ],
    },
  ],
  user: [
    {
      path: '/user1',
      meta: {
        name: '会员管理',
        icon: 'iconhuiyuan',
      },
      children: [
        {
          path: '/user/memberList',
          code: 'MemberMenu',
          meta: {
            name: '会员列表',
            icon: '',
          },
        },
        {
          path: '/user/level',
          code: 'LevelPermissionMenu',
          meta: {
            name: '等级权限',
            icon: '',
          },
        },
        {
          path: '/user/task',
          code: 'TaskSettingMenu',
          meta: {
            name: '任务设置',
            icon: '',
          },
        },
      ],
    },
    {
      path: '/user2',
      meta: {
        name: '用户配置',
        icon: 'iconusersetting',
      },
      children: [
        {
          path: '/user/list',
          code: 'UserMenu',
          meta: {
            name: '用户列表',
            icon: '',
          },
        },
        {
          path: '/user/role',
          code: 'UserRoleMenu',
          meta: {
            name: '角色权限',
            icon: '',
          },
        },
      ],
    },
  ],
  operation: [
    {
      path: '/operation/messageSet',
      code: 'MessageSettingMenu',
      meta: {
        name: '消息设置',
        icon: 'iconhuiyuan',
      },
    },
  ],
  statistics: [
    {
      path: '/statistics/globalStatistics',
      code: 'TrafficStatisticsMenu',
      meta: {
        name: '全局统计',
        icon: 'iconicon_quanjutongji',
      },
    },
    {
      path: '/statistics/productStatistics',
      code: 'ProductStatisticsMenu',
      meta: {
        name: '产品统计',
        icon: 'iconicon_chanpintongji',
      },
    },
  ],
  collage: [
    {
      path: '/collage/jobs',
      code: 'JobMenu',
      meta: {
        name: '智能评价',
        newIcon: 'icon-zzj-baseline-people_homework',
      },
    },
    {
      path: '/collage/classes',
      code: 'ClassMenu',
      meta: {
        name: '班级管理',
        newIcon: 'icon-zzj-baseline-people_class',
      },
    },
    {
      path: '/collage/SimulationTraining',
      code: 'DesignTaskMenu',
      meta: {
        name: '定制仿真',
        newIcon: 'icon-zzj-baseline_people_custom',
      },
    },
    {
      path: '/3',
      meta: {
        name: '资源库',
        newIcon: 'icon-zzj-baseline_people_item',
      },
      children: [
        // {
        //   path: '/collage/theoreticalQuestion',
        //   meta: {
        //     name: '理论题',
        //     icon: '',
        //   },
        // },
        {
          code: 'QuestionMenu',
          path: '/collage/BIMPracticalOperationQuestion',
          meta: {
            name: '模型库',
            icon: '',
          },
        },
        {
          code: 'DesignFileMenu',
          path: '/collage/customizedSimulationResources',
          meta: {
            name: '定制仿真资源',
            icon: '',
          },
        },
        {
          code: 'DocumentFileMenu',
          path: '/collage/database',
          meta: {
            name: '资料库',
            icon: '',
          },
        },
        {
          code: 'DesignQuestionMenu',
          path: '/collage/questionBank',
          meta: {
            name: '题库',
            icon: '',
          },
        },
      ],
    },
    {
      path: '/collage/persons',
      code: 'PersonMenu',
      meta: {
        name: '用户列表',
        newIcon: 'icon-zzj-baseline-people_user',
      },
    },
  ],
}
