
import Vue from "vue";

export default Vue.extend({
  computed: {
    breadcrumbList() {
      console.log(this.$store.state.breadcrumbList);
      return this.$store.state.breadcrumbList;
    }
  }
});
