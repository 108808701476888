
import Vue from "vue";
import MenuItemLink from "./MenuItemLink.vue";

export default Vue.extend({
  name: "MenuItem",
  props: ["item", "isFirstLevel"],
  components: {
    MenuItemLink
  },
  computed: {
    isCollapse() {
      return this.$store.state.isCollapse;
    }
  }
});
